﻿.dash {
    flex-grow: 2;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:stretch;

    &__search {
        padding: rems(10px) rems(20px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .search__text, .select {
            background-color: var(--color--white);
            border-radius: rems(20px);
            color: var(--color--brand-charcoal-darker);
            border: none;
            outline: none;
        }

        .search__text {
            display: inline-block;
            width: rems(150px);
            padding: rems(12px) rems(10px);
            margin-right: rems(10px);
        }

        .select {
            padding: rems(12px) rems(0px) rems(12px) rems(10px);
            margin-right: rems(5px);
        }

        .search__lbl, .summary__lbl {
            margin-right: rems(10px);
        }

        .input--date {
            margin-right: rems(20px);

            &2 {
                margin-right: rems(5px) !important;
            }
        }

        .btn {
            margin-right: rems(10px);

            &:last-child {
                margin-right: 0;
            }
        }

        .btn-icon2 {
            margin-right: rems(20px);
        }
    }

    .no-results {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
