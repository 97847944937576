﻿.icon {
    display: inline-block;
    width: rems(32px);
    height: rems(32px);
    cursor: pointer;
    background-position: center center;
    background-size: cover;

    &--small {
        width: rems(16px);
        height: rems(16px);
    }

    &--pending-actions--charcoal {
        background-image: url('images/icons/pending-actions--charcoal.svg');
    }

    &--day--charcoal {
        background-image: url('images/icons/day--charcoal.svg');
    }

    &--week--charcoal {
        background-image: url('images/icons/week--charcoal.svg');
    }

    &--search--charcoal {
        background-image: url('images/icons/search--charcoal.svg');
    }

    &--close--charcoal {
        background-image: url('images/icons/close--charcoal.svg');
    }

    &--close--white {
        background-image: url('images/icons/close--white.svg');
    }

    &--back--charcoal {
        background-image: url('images/icons/back--charcoal.svg');
    }

    &--service-red {
        background-image: url('images/icons/service--red.svg');
    }

    &--transport-red {
        background-image: url('images/icons/transport--red.svg');
    }

    &--refresh--charcoal {
        background-image: url('images/icons/refresh--charcoal.svg');
    }

    &--error-white {
        background-image: url('images/icons/warning--white.svg');
    }

    &--logout-red {
        background-image: url('images/icons/logout--red.svg');
    }

    &--login-red {
        background-image: url('images/icons/login--red.svg');
    }

    &--arrow-right--white {
        background-image: url('images/icons/arrow-right--white.svg');
    }

    &--summary-log--charcoal {
        background-image: url('images/icons/summary-log--charcoal.svg');
    }
}
