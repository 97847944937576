﻿.blades {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include tablet {
        flex-direction: row;
    }

    .blade {
        padding: rems(10px) rems(5px);

        @include tablet {
            padding: rems(10px) 0;
            height: 100%;
        }

        @include desktop {
            padding: rems(10px);
        }

        &--2 {
            position: relative;
            width: 100%;

            .blade__sub {
                display: flex;
                flex-direction: column;
                height: 100%;
                border: solid #ffffff rems(10px);

                .head {
                    padding: 0 rems(5px);
                    padding-bottom: rems(10px);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: var(--color--white);
                    background-color: var(--color--brand-charcoal-darker);
                    box-shadow: 0px 4px 5px -1px rgba(0,0,0,0.25);

                    @include tablet {
                        flex-direction: row;
                        padding-bottom: 0;
                    }

                    .title {
                        padding: rems(10px);
                        text-align: left;
                        flex-grow: 2;
                        font-weight: var(--font-weight--bold);

                        .dull {
                            font-weight: var(--font-weight--light);
                        }

                        .icon {
                            vertical-align: middle;
                        }

                        .close {
                            margin-left: rems(10px);
                            cursor: pointer;
                        }
                    }

                    .controls {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .toggle {
                            height: 100%;
                            padding: rems(5px) 0;
                            margin-right: rems(10px);
                        }

                        .refresh {
                            width: rems(60px);
                        }
                    }
                }

                .data {
                    flex-grow: 2;
                    position: relative;

                    .data-grid, .po {
                        position: absolute;
                        height: 100%;
                        inset: 0;
                        overflow-y: auto;
                        cursor: pointer;
                    }

                    .no-data {
                        position: absolute;
                        display: none;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-color: var(--color--canvas);
                        background-image: url('images/icons/pending-actions--charcoal.svg');
                        background-position: center center;
                        background-size: contain;
                        background-repeat: no-repeat;

                        &.active {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
