﻿.data-grid {
}

.state {
    display: block;
    width: 100%;
    text-align: center;
    padding: rems(5px) rems(10px);
    border-radius: rems(5px);

    &--loaded-successfully {
        background-color: var(--color--good); // Green
    }

    &--loaded-with-errors {
        background-color: var(--color--highlight); // Amber
    }

    &--mapping-error, &--queuing-error, &--loading-failed {
        background-color: var(--color--warn); // Red
    }
}

.selected {
    display: block;
    width: 30px;
    padding: 0;
    height: 100%;
    color: transparent;
    background-color: var(--color--brand-charcoal-darker); 
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
