.transaction {
    &__head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: rems(10px) rems(5px) rems(5px) rems(5px);

        .head__title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: rems(10px);

            .state {
                margin-right: rems(20px);
            }

            .title__label {
                white-space: nowrap;
            }

            .close__btn {
                margin-right: rems(20px);
            }
        }
    }


    &__title {
        margin: 0;
        margin-right: rems(20px);
        padding: 0px rems(10px);
        text-transform: capitalize;
        background-color: #1976d2;
        color: var(--color--white);

        &--trans {
            margin-top: 0;
            background-color: transparent;
            color: var(--color--charcoal);
        }
    }
}

.row {
    &__cell, &__val {
        padding: rems(10px);
        display: inline-block;
    }

    &__cell {
        font-weight: bold;
        text-align: right;
        width: 40%;
        background-color: var(--color--canvas--darker);

        @include tablet {
            width: 33%;
        }

        @include desktop {
            width: 25%;
        }

        @include desktop-l {
            width: 20%;
        }

        @include desktop-xl {
            width: 15%;
        }
    }

    &__val {
        text-align: left;
        width: 50%;

        @include tablet {
            width: 60%;
        }

        @include desktop {
            width: 70%;
        }

        @include desktop-l {
            width: 70%;
        }

        @include desktop-xl {
            width: 70%;
        }
    }

    &--else {
        border-top: solid 1px #000;
        border-right: solid 1px #000;
        border-left: solid 1px #000;
    }
}
