﻿.tabs {
    width: 100%;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: rems(20px);
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: var(--color--brand-charcoal);

        .header__btn {
            border: none;
            outline: none;
            margin-right: rems(10px);
            transition: opacity 0.3s ease-in-out;
            background-color: transparent;
            color: var(--color--white);
            opacity: 0.6;
            font-size: var(--font-size);
            font-weight: 300;
            padding-bottom: rems(10px);
            color: var(--color--brand-red--lighter);
            cursor: pointer;

            &.active {
                opacity: 1;
                border-bottom-width: 5px;
                border-bottom-style: solid;
                font-weight: 700;
                border-bottom-color: var(--color--brand-red);
            }
        }
    }

    &__tabs {
        .tab {
/*            opacity: 0;
            height: 0;
            transition: height 0.3s ease-in-out;*/
            display: none;

            &.active {
                display:block;
/*                animation-duration: 0.3s;
                animation-fill-mode: forwards;
                animation-name: panel-in-out;
                z-index: 1;*/
            }
        }
    }
}
