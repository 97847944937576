.table {
    width: 100%;
    font-weight: 400;
    font-size: rems(16px);
    line-height: 140%;
    margin-bottom: rems(30px);
    border-top: solid 1px #808080;
    border-left: solid 1px #808080;

    th {
        text-align: left;
    }

    td, th {
        border-bottom: solid 1px #808080;
        border-right: solid 1px #808080;
    }

    th, td {
        padding: rems(10px);
    }

    .label, th {
        font-weight: var(--font-weight--bold);
        background-color: var(--color--canvas--darker);
        max-width: rems(100px);
    }

    .label {
        text-align: right;
        text-transform: capitalize;
    }

    .cell {
        &--sm {
            width: 15%;
        }

        &--md {
            width: 20%;
        }

        &--lg {
            width: 40%;
        }
    }

    .row{
        &--highlight{
            background-color: var(--color--highlight);
        }
    }
}
