﻿.btn {
    cursor: pointer;
    border: none;
    outline: none;
    text-transform: uppercase;
    padding: rems(10px) rems(15px);

    &-icon {
        border: none;
        outline: none;
        background-color: transparent;
        display: inline-block;
        width: rems(32px);
        height: rems(32px);
        cursor: pointer;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &--error, &--link {
        font-size: var(--font-size--md);
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 0.75;
        }
    }

    &--error {
        padding: rems(5px) rems(15px);
        border: none;
        outline: none;
        border-radius: rems(5px);
        color: var( --color--brand-red);
    }

    &--link {
        padding: rems(5px) rems(15px);
        border: none;
        outline: none;
        background-color: transparent;
        color: var(--color--white);
        font-weight: var(--font-weight);
        text-decoration: underline;
    }

    &--primary {
        border-radius: rems(20px);
        color: var(--color--white);
        border: solid 3px var(--color--brand-red);
        background-color: var(--color--brand-red);
        transition: all 0.3s ease-in-out;

        &:hover {
            border: solid 3px var(--color--brand-charcoal);
            background-color: var(--color--brand-charcoal);
        }
    }

    &--secondary {
        border-radius: rems(20px);
        color: var(--color--black);
        background-color: transparent;
        border: solid 3px var(--color--black);
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: var(--color--white);
        }
    }
}
